<template>
	<v-app class="fs_main home">
		<v-container>
			<MapBlock
				:zoom="zoom"
				:markers="markers"
				:fitness="fitness"
				:maptype="maptype"
				:center="center"
				class="fs_map"
			></MapBlock>
			<v-footer>
				©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
			</v-footer>
		</v-container>
		<div class="text-center">
			<v-dialog v-model="isStart" width="500">
				<v-card>
					<v-card-title class="headline"> FieldShareへようこそ </v-card-title>
					<v-card-text
						>これは、初回起動時のみ表示されるダイアログです。FieldShareのセットアップを行うため、まずは以下の始めるボタンを押してセットアップを開始してください</v-card-text
					>
					<v-card-actions>
						<v-btn
							style="width: 100%"
							color="green darken-1"
							text
							@click="start"
						>
							始める
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</v-app>
</template>

<script>
import MapBlock from "@/components/MapBlock.vue";
import axios from "axios";

export default {
	components: {
		MapBlock,
	},
	mounted() {
		// Startページから来ている場合、本当にこれが初回起動かチェック
		if (this.$store.state.isStartPage) {
			axios.defaults.headers.common["Authorization"] =
				"Bearer " + this.$store.state.auth.data.token;
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/appinfo?startCheck=true"
				)
				.then((res) => {
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.isStart = true;
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
		document.getElementsByTagName('main')[0].setAttribute("style", "height:100%;");
	},
	methods: {
		start() {
			// 初回起動時なのでDBをセットアップして成功したらページをリロードする
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/appinfo?start=true&uid=" +
						this.$store.state.user.data.id
				)
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.isStart = false;
						this.$store.commit("SET_STARTPAGE", false);
						this.$router.go({
							path: this.$router.currentRoute.path,
							force: true,
						});
					} else {
						console.log("Fail: " + JSON.stringify(res));
						this.$store.commit("SET_STARTPAGE", false);
					}
				})
				.catch((error) => {
					console.log(error);
					this.$store.commit("SET_STARTPAGE", false);
				});
		},
	},
	computed: {
		markers: function () {
			let data = [];
			for (var i = 0; i < this.$store.state.places.data.length; i++) {
				let item = this.$store.state.places.data[i];
				let element = {
					id: item.id,
					lat: parseFloat(item.latitude),
					lng: parseFloat(item.longitude),
					icon: {
						color: "#dd445f",
					},
				};
				data.push(element);
			}
			return data;
		},
	},
	data() {
		return {
			isStart: false,
			zoom: 17,
			fitness: true,
			maptype: "terrain",
			center: { lat: 35.658803664298865, lng: 139.70246614757016 },
		};
	},
};
</script>
